import React from "react"
import { graphql } from "gatsby"
import { Flex, Box, Heading, Text } from "rebass/styled-components"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="O nás" />
    <Flex
      flexDirection="column"
      alignItems="center"
      flexGrow="grow"
      justifyContent="flex-start"
      width="100%"
      height="100%"
    >
      <Flex width="100%" flexWrap="wrap">
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={[1, 1, 1, 1, 1 / 2]}
          p={[5]}
          sx={{ textAlign: "center" }}
          height="50vh"
        >
          <Heading fontSize={6} color="accent" my={2}>
            Pink emely
          </Heading>
          <Text
            as="p"
            fontFamily="body"
            fontWeight="light"
            fontSize={2}
            lineHeight="body"
            color="text"
            mb="3"
          >
            Pink Emely Café je naše pohodová kavárna, která se nachází v 1.
            patře obchodního centra Opatovská. Výbornou kávu či některou z
            našich specialit si můžete vychutnat v pohodlí plyšových křesílek, a
            užít si tak zasloužený relax plnými doušky.
          </Text>
        </Flex>
        <Box width={[1, 1, 1, 1, 1 / 2]} height="50vh">
          <BackgroundImage
            style={{
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
            fluid={data.right.childImageSharp.fluid}
          />
        </Box>
      </Flex>

      <Flex width="100%" flexWrap="wrap">
        <Box width={[1, 1, 1, 1, 1 / 2]} height="50vh" order={[2, 2, 2, 2, 0]}>
          <BackgroundImage
            style={{
              height: "100%",
              width: "100%",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat",
            }}
            fluid={data.left.childImageSharp.fluid}
          />
        </Box>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={[1, 1, 1, 1, 1 / 2]}
          p={[5]}
          sx={{ textAlign: "center" }}
          height="50vh"
        >
          <Text
            as="p"
            fontFamily="body"
            fontWeight="light"
            fontSize={2}
            lineHeight="body"
            color="text"
            mb="3"
          >
            Rádi Vás pohostíme domácími zákusky a chlebíčky, zapečeme křupavý
            sendvič nebo panini, připravíme sladké wafle... Na větší hlad
            doporučujeme naše snídaňová menu, těstoviny, polévky či saláty. K
            pití můžeme nabídnout lahodné kávové speciality, teplé a studené
            nápoje včetně domácích limonád, sklenku dobrého vína nebo třeba
            koktejl. Rádi Vám mimo jiné uspořádáme oslavu narozenin, nebo
            připravíme dort dle vlastního výběru s sebou.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  </Layout>
)

export const query = graphql`
  query {
    right: file(relativePath: { eq: "about01.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    left: file(relativePath: { eq: "about02.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
